export default (p) => {
  // let leaves = [];
  let utopien = [];

  // p.state = [];
  // p.dispatch = () => {};

  p.setup = () => {
    p.createCanvas(p.windowWidth, p.windowHeight);

    p.noSmooth();

    for (let m = 0; m < 8; m++) {
      // Utopien (position , celocity , time)
      let timetobreak = 100;
      // p.println(100);
      let u = new Utopien(
        p,
        p.createVector((m * p.width) / 8, p.height),
        p.createVector(0, -4),
        timetobreak
      );
      utopien.push(u);
    }
  };

  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth, p.windowHeight);
    let timetobreak = 100;
    for (let m = 0; m < 4; m++) {
      var u = new Utopien(
        p,
        p.createVector((m * p.windowWidth) / 4, p.windowHeight),
        p.createVector(0, -4),
        timetobreak
      );
      utopien[m] = u;
    }
  };

  p.draw = () => {
    // p.background(255, 0);
    for (let i = 0; i < utopien.length; i++) {
      utopien[i].draw();
    }
  };

  class Utopien {
    constructor(p, pos, v, t) {
      this.three = [];
      this.p = p;
      let b = new Branch(this.p, pos, v, t);
      this.three.push(b);
    }
    draw() {
      for (let i = 0; i < this.three.length; i++) {
        // Get the branch, update and draw it
        this.three[i].update();
        this.three[i].render();

        if (this.three[i].timeToBranch()) {
          // p.println(this.three.length);
          if (this.three.length < 1200) {
            this.three.push(this.three[i].branch(this.p.random(5, 7))); // Add one going right
            this.three.push(this.three[i].branch(this.p.random(-5, -7))); // Add one going left
          }
        }
      }
    }
  }

  class Branch {
    constructor(p, start, vel, n) {
      this.p = p;
      this.start = start.copy();

     this.vel = vel.copy();
      // this.vel = s.copy();
      this.end = start.copy();

      this.timerstart = n;
      this.timer = n;

      this.growing = true;
      this.newvel = this.p.createVector(0,0);
    }
    update() {
      if (this.growing) {
        this.end.add(this.vel);
      }
    }

    render() {
      p.stroke(221, 160, 221);

      p.strokeWeight(1);
      p.line(this.start.x, this.start.y, this.end.x, this.end.y);
    }

    timeToBranch() {
      this.timer--;
      if (this.timer < 0 && this.growing) {
        this.growing = false;
        return true;
      } else {
        return false;
      }
    }

    branch(angle) {
      // What is my current heading
      let theta = this.vel.heading();
      // What is my current speed
      let m = this.vel.mag() * this.p.random(0.5, 1.2);
      // Turn me
      theta += this.p.radians(angle);
      this.newvel = this.p.createVector(m * this.p.cos(theta), m * this.p.sin(theta));
      // console.log(this.vel);
      // Return a new Branch
      return new Branch(this.p, this.end, this.newvel, this.timerstart * 1.2);
    }
  }
};
